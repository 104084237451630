<template>
  <div class="w-11/12 flex flex-col justify-center items-center">
    <sub-head1 :mainText="'よくある質問'" :subText="'お答えします！'" />
    <sub-head2
      :mainText="'株探プレミアム会員なくても使用できますか？'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-main'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value: '使用できます。',
        },
        {
          value:
            'プレミアム会員の場合は会員限定のコンテンツ(例：最大24期までの通期決算)も株探拡張がしっかりグラフ化します',
          emphasis: true,
        },
      ]"
    />
    <sub-head2
      :mainText="'スマホ対応はしないのですか？'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-main'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value: '仕様上、chrome拡張はスマホでは使用できません。',
        },
      ]"
    />
    <sub-head2
      :mainText="'追加してほしい機能があります！'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-main'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value:
            '株探拡張はユーザーからのリクエストや改善要望を積極的に受け付けております。より優れた株式分析ツールを目指すためにもフィードバックが必要です。',
        },
      ]"
    />
    <!-- <div class="w-[90%] pl-2 md:pl-4">
      <Tweet tweet-id="1667881485259669504" />
    </div> -->

    <div
      class="w-[90%] flex flex-col gap-3 my-4 items-start pl-2 md:pl-4 font-base text-base md:text-xl text-fmfm-darkgray"
    >
      <div>ご要望はこちらまで</div>
      <div class="w-full flex flex-row items-center gap-2 hover:underline text-sub" @click="openTwitter">
        <fa class="!w-6 md:!w-8 hover:scale-105" icon="twitter" type="fab" />Twitter DM (@munrosan)
      </div>
      <div class="w-full hover:underline text-sub">
        <a class="!k-text-[#1a202c] flex flex-row items-center gap-2" href="mailto:munroyello@gmail.com">
          <fa class="!w-6 md:!w-8 hover:scale-105" icon="envelope" />メールはこちらへ
        </a>
      </div>
    </div>
    <sub-head2
      :mainText="'解約方法を教えてください'"
      :numbering="'Q'"
      :numberingStyle="'!text-white bg-main'"
      :textColor="'text-black'"
    />
    <text-area
      :texts="[
        {
          value:
            '株探拡張のchrome拡張アイコンをクリックしてログイン ▶ 支払い情報の確認 ▶ プランをキャンセルの３ステップで解約が完了します。',
        },
      ]"
    />
    <!-- STEP8 -->
    <div class="w-[90%] flex flex-col md:flex-row justify-start items-start">
      <img class="w-1/2 md:w-1/2 border-none shadow-md rounded-md" :src="require(`@/assets/cancel.gif`)" />
    </div>
    <!-- <img-and-text
      :title="'カスタムリンク'"
      :img="'cancel.gif'"
      :numbering="'8'"
      :noBorder="true"
      :texts="[
        {
          value:
            '銘柄ページに他の情報サイトへのリンクを設置できます。リンク先は自由に設定できます。株探を起点にして、足りない情報はカスタムリンクで飛べるように設定すれば、より情報収集が捗ることでしょう！',
        },
      ]"
    >
    </img-and-text> -->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SubHead1 from '../components/SubHead1.vue'
import SubHead2 from '../components/SubHead2.vue'
import TextArea from '../components/TextArea.vue'
import ImgAndText from '../components/ImgAndText.vue'
export default defineComponent({
  name: 'QuestionView',
  props: {},
  components: {
    SubHead1,
    SubHead2,
    TextArea,
    //ImgAndText,
  },
  methods: {
    openTwitter() {
      window.open('https://twitter.com/munrosan')
    },
  },
})
</script>
