import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "font-base w-[90%] pl-0 md:pl-4 py-4 text-base md:text-xl" }
const _hoisted_2 = ["href"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.texts, (text) => {
      return (_openBlock(), _createElementBlock("span", {
        class: _normalizeClass(["leading-[2.4rem] md:leading-[3rem]", text.emphasis ? 'text-main' : 'text-fmfm-darkgray'])
      }, [
        (text.showLink)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "text-blue-400 underline block",
              href: text.link,
              target: "_blank"
            }, _toDisplayString(text.value), 9, _hoisted_2))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(text.value), 1))
      ], 2))
    }), 256))
  ]))
}