
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TextArea',
  props: {
    texts: {
      type: Array as () => {
        value?: string
        emphasis?: boolean
        showLink?: boolean
        link?: string
      }[],
      default: () => [],
    },
  },
})
