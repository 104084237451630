<template>
  <div
    :class="`font-${textFont} w-full ${textColor} pl-2 md:pl-4 py-2 md:py-4 flex flex-row gap-2 justify-start items-center text-base md:text-3xl`"
  >
    <div :class="`text-white ${numberingStyle} rounded-full w-7 md:w-10 h-7 md:h-10 flex justify-center items-center`">
      {{ numbering }}
    </div>
    <div v-html="mainText"></div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SubHead2',
  props: {
    mainText: {
      type: String,
      required: true,
    },
    numbering: {
      type: String,
      default: '',
      required: true,
    },
    numberingStyle: {
      type: String,
      default: '',
      required: false,
    },
    textFont: {
      type: String,
      default: 'accent',
      required: false,
    },
    textColor: {
      type: String,
      default: 'text-black',
      required: false,
    },
  },
})
</script>
