
import { defineComponent } from 'vue'
import HeaderView from '../views/HeaderView.vue'
import SectionRow from '../components/SectionRow.vue'
export default defineComponent({
  data() {
    return {
      isLp: true,
    }
  },
  components: {
    HeaderView,
    SectionRow,
  },
})
