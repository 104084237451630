import { Firestore, collection, getDocs, query, where } from 'firebase/firestore'

// 課金ユーザーか？
const isPremiumUser = async (db: Firestore, uid: string) => {
  const q = query(
    collection(db, `/customers/${uid}/subscriptions`),
    where('status', 'in', ['trialing', 'active']),
    where('current_period_end', '>', new Date())
  )
  const querySnapshot = await getDocs(q)
  //console.log("query snap shot")
  return !querySnapshot.empty
}

const isTrialingUser = async (db: Firestore, uid: string) => {
  const q = query(
    collection(db, `/customers/${uid}/subscriptions`),
    where('status', '==', 'trialing'),
    where('current_period_end', '>', new Date())
  )
  const querySnapshot = await getDocs(q)
  //console.log("query snap shot")
  return !querySnapshot.empty
}
export { isPremiumUser, isTrialingUser }
