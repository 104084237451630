<template>
  <div class="w-11/12 flex flex-col justify-center items-center gap-4">
    <sub-head1 :mainText="'料金について'" :subText="'今なら7日間の無料キャンペーン中！'" />
    <div class="w-full flex flex-col justify-center items-center">
      <img src="../assets/price.png" />
    </div>
    <sub-head1 :mainText="''" :subText="'月額¥1,320(税込)で全ての機能を制限なくご利用いただけます。'" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SubHead1 from '../components/SubHead1.vue'
//import TextArea from '../components/TextArea.vue'
export default defineComponent({
  name: 'PriceView',
  props: {},
  components: {
    SubHead1,
    //TextArea,
  },
})
</script>
