<template>
  <div class="w-11/12 flex flex-col">
    <sub-head1 :mainText="mainText" :subText="subText" />
    <!-- STEP1 -->
    <img-and-text
      :title="'インストール方法'"
      :img="'installation/step1.gif'"
      :numbering="'1'"
      :noBorder="true"
      :texts="[
        {
          value:
            '株探拡張proはChromeの拡張機能です。パソコンを準備してブラウザ(Google ChromeまたはEdge)を立ち上げてください。スマホには対応しておりません。',
        },
      ]"
    />

    <!-- STEP2 -->
    <img-and-text
      :title="'株探拡張proをインストール'"
      :img="'installation/step2.gif'"
      :noBorder="true"
      :numbering="'2'"
      :texts="[
        {
          value: 'インストールはこちらから',
          showLink: true,
          link: 'https://chrome.google.com/webstore/detail/%E6%A0%AA%E6%8E%A2%E6%8B%A1%E5%BC%B5pro/emohicbcainjadecjkiepgfhdinocpfe?hl=ja',
        },
        {
          value: 'Chromeに追加をクリックしてください。',
        },
      ]"
    />

    <!-- STEP3 -->
    <img-and-text
      :title="'さあ始めよう！'"
      :img="'installation/step3.gif'"
      :numbering="'3'"
      :noBorder="true"
      :texts="[
        {
          value:
            '株探を開いて更新すると「株探拡張pro」が使えるようになっています！Googleアカウントでログインして始めましょう！',
        },
      ]"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SubHead1 from '../components/SubHead1.vue'
import ImgAndText from '../components/ImgAndText.vue'
export default defineComponent({
  name: 'InstallationGuide',
  props: {
    subText: {
      type: String,
      require: true,
      default: '３ステップにまとめました！',
    },
    mainText: {
      type: String,
      require: true,
      default: 'インストール方法',
    },
  },
  data: function () {
    return {
      noBorder: true,
    }
  },
  components: {
    SubHead1,
    ImgAndText,
  },
})
</script>
