import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_overlay_view = _resolveComponent("overlay-view")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_bottom_view = _resolveComponent("bottom-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_overlay_view),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode(_component_bottom_view)
    ])
  ], 64))
}