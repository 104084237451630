
import OverlayView from './views/OverlayView.vue'
import { defineComponent } from 'vue'
import BottomView from './views/BottomView.vue'

export default defineComponent({
  // ログイン画面
  name: 'App',

  components: {
    OverlayView,
    BottomView,
  },
})
