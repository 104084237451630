<template>
  <div class="w-full py-2 flex flex-row justify-between items-center bg-white">
    <div class="pl-2 w-[128px] md:w-[200px]" @click="moveTopPage">
      <img src="../assets/logo.png" />
    </div>

    <div v-if="!isLp">
      <!--ログイン済み-->
      <div class="m-2 gap-2 flex flex-row items-center" v-if="isAuthenticated">
        <img class="rounded-full h-10" :src="photoURL" @click="showUserMenu" />
        <div class="flex flex-col justify-start items-start">
          <div class="flex flex-row items-center gap-1">
            <p class="text-slate-900 text-sm">{{ displayName }}</p>
            <p
              :class="{
                'p-1 rounded-md text-xs text-white': true,
                'bg-amber-300': isPremiumUser,
                'bg-green-500': !isPremiumUser,
              }"
            >
              {{ status }}会員
            </p>
            <fa class="h-4 text-slate-700 hover:scale-125" :icon="'sign-out'" @click="signout" />
          </div>
          <p class="text-slate-700 text-xs overflow-clip">{{ email }}</p>
        </div>
      </div>
      <!--未ログイン-->
      <div v-else>
        <div
          class="mr-1 flex flex-row justify-center items-center gap-1 bg-sub text-white shadow-xl border-gray-200 rounded-2xl p-2 hover:scale-105 text-xs md:text-base"
          v-if="!isAuthenticated && !isLp"
          v-bind:class="{
            'border-4 !border-orange-400': isWarning,
          }"
          @click="signin"
        >
          <svg aria-hidden="true" width="32" height="32" viewBox="0 0 18 18">
            <path
              d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 002.38-5.88c0-.57-.05-.66-.15-1.18z"
              fill="#4285F4"
            ></path>
            <path
              d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 01-7.18-2.54H1.83v2.07A8 8 0 008.98 17z"
              fill="#34A853"
            ></path>
            <path d="M4.5 10.52a4.8 4.8 0 010-3.04V5.41H1.83a8 8 0 000 7.18l2.67-2.07z" fill="#FBBC05"></path>
            <path
              d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 001.83 5.4L4.5 7.49a4.77 4.77 0 014.48-3.3z"
              fill="#EA4335"
            ></path>
          </svg>
          <div>Googleでログイン</div>
          <div></div>
        </div>
        <p v-if="isWarning" class="text-sm md:text-base">先にログインしてください</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useStore } from '../store'
import { useRouter } from 'vue-router'

export default defineComponent({
  // ログイン画面
  name: 'HeaderView',
  components: {},
  props: {
    isLp: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      visibleUserMenu: false,
      store: useStore(),
      router: useRouter(),
    }
  },
  computed: {
    isWarning() {
      return this.store.state.isWarning
    },
    isAuthenticated() {
      return this.store.state.userInfo.isAuthenticated
    },
    isPremiumUser() {
      return this.store.state.userInfo.isPremiumUser
    },
    displayName() {
      return this.store.state.userInfo.displayName
    },
    photoURL() {
      return this.store.state.userInfo.photoURL
    },
    email() {
      return this.store.state.userInfo.email
    },
    status() {
      if (this.store.state.userInfo.isTrialingUser) return '有料(トライアル)'
      return this.store.state.userInfo.isPremiumUser ? '有料' : '無料'
    },
  },
  setup() {
    return {}
  },
  methods: {
    signin() {
      this.store.dispatch('signin', { token: undefined })
    },
    moveTopVip() {
      this.$router.push('/vip')
    },
    moveTopPage() {
      this.$router.push('/')
    },
    showUserMenu() {
      this.visibleUserMenu = !this.visibleUserMenu
    },
    onCloseClick() {
      this.visibleUserMenu = false
    },
    signout() {
      this.store.dispatch('signout')
      this.visibleUserMenu = false
      this.router.push('/')
    },
  },
})
</script>
