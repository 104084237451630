<template>
  <div
    :class="`w-full py-4 flex flex-col justify-center items-center ${
      noBorder ? 'border-none' : 'border-b-1 md:border-b-4 pb-10'
    } border-gray-200 gap-4`"
  >
    <sub-head2 :mainText="title" :numbering="numbering" :numberingStyle="numberingStyle" />
    <slot></slot>
    <div class="w-full flex flex-col md:flex-row justify-center items-center gap-4">
      <img class="w-4/5 md:w-1/2 border-none rounded-md" :src="require(`@/assets/${img}`)" />
      <text-area :texts="texts" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SubHead2 from '../components/SubHead2.vue'
import TextArea from '../components/TextArea.vue'
export default defineComponent({
  name: 'ImgAndText',
  props: {
    title: {
      type: String,
      required: true,
      default: 'タイトル',
    },
    numbering: {
      type: String,
      required: true,
      default: '1',
    },
    numberingStyle: {
      type: String,
      required: true,
      default: 'bg-black',
    },
    img: {
      type: String,
      required: true,
      default: '',
    },
    noBorder: {
      type: Boolean,
      required: false,
      default: false,
    },
    texts: {
      type: [],
      required: true,
      default: [
        {
          value: 'texts',
        },
      ],
    },
  },
  components: {
    SubHead2,
    TextArea,
  },
})
</script>
