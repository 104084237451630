<template>
  <div class="w-11/12 flex flex-col justify-center items-center">
    <sub-head1 :mainText="'7日間以内に解約すればタダ！'" :subText="'まずは株探拡張のパワーを試して頂きたいです！'" />
    <img class="" src="../assets/free_trial.png" />
    <text-area
      :texts="[
        {
          value:
            'VIPプランを気軽に試して頂きたいので7日間の無料期間を用意しています。始めての購入であれば7日間以内に解約すればいっさいの費用はかかりません。解約も簡単にできるようになっています。',
        },
      ]"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SubHead1 from '../components/SubHead1.vue'
export default defineComponent({
  name: 'CancelView',
  props: {},
  components: {
    SubHead1,
  },
})
</script>
