<template>
  <div class="w-11/12 flex flex-col">
    <sub-head1 :mainText="'機能の紹介'" :subText="'欲しかったあの機能を叶えます'" />

    <!-- STEP0 -->
    <!-- <img-and-text
      :title="'洗練されたUI'"
      :img="'function/before_after.gif'"
      :numbering="'0'"
      :noBorder="false"
      :texts="[
        {
          value:
            '通期決算、四半期決算、キャッシュフロー、配当金の推移をグラフ化します。ひと目見ただけでその銘柄の財務的な特徴が理解できます。投資判断に必要な基本的な情報収集はこのページだけで完結します！',
        },
      ]"
    >
    </img-and-text> -->

    <!-- STEP1 -->
    <img-and-text
      :title="'決算グラフ化'"
      :img="'function/finance.gif'"
      :numbering="'1'"
      :noBorder="false"
      :texts="[
        {
          value:
            '通期決算、四半期決算、キャッシュフロー、配当金の推移を見える化します。エクセル等は一切不要。このページを見るだけで基本的な情報収集が完結します！米国株にも対応しています。',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP2 -->
    <img-and-text
      :title="'株主グラフ化'"
      :img="'function/holder.gif'"
      :numbering="'2'"
      :noBorder="false"
      :texts="[
        {
          value:
            '株主構成を見える化します。株主構成から将来的な経営安定性や売買圧力を推測し、長期的な投資に役立てましょう！',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP3 -->
    <img-and-text
      :title="'関連銘柄チャート比較'"
      :img="'function/chart_compare.gif'"
      :numbering="'3'"
      :noBorder="false"
      :texts="[
        {
          value: '関連銘柄の株価チャートを比較します。ワンクリックで関連銘柄の高機能チャートをまとめて比較できます。',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP4 -->
    <img-and-text
      :title="'関連銘柄PPM分析'"
      :img="'function/ppm.gif'"
      :numbering="'4'"
      :noBorder="false"
      :texts="[
        {
          value:
            '関連銘柄の収益力を比較します。横軸を利益率、縦軸を売上高に取った独自のグラフから関連銘柄の収益力を比較することができます。投資検討している銘柄がその業界で「勝ち組企業」なのかを簡単にチェックしましょう！',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP5 -->
    <img-and-text
      :title="'イベントチャート'"
      :img="'function/tracing_news.gif'"
      :numbering="'5'"
      :noBorder="false"
      :texts="[
        {
          value:
            '株価とニュースを連動させて表示します。過去の値動きと、その時の材料を把握することで今後新しい材料が出たときの株価への影響を予測し、トレードに役立てることができます！',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP6 -->
    <img-and-text
      :title="'決算カレンダー'"
      :img="'function/calender.gif'"
      :numbering="'6'"
      :noBorder="false"
      :texts="[
        {
          value:
            '決算銘柄をカレンダー形式で表示します。「業績変化と株価の反応をまとめて確認できるカレンダーが欲しい..」という投資家の夢を叶えました。決算シーズンはこまめにチェックすることをおすすめします。光る銘柄をいち早く探し出し、大きな利益を狙いましょう！',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP7 -->
    <img-and-text
      :title="'テーマ株のチャート一括表示'"
      :img="'function/thema.gif'"
      :numbering="'7'"
      :noBorder="false"
      :texts="[
        {
          value:
            '株探が「株価注意報」や「銘柄探検」ページで提供している各テーマの銘柄もまとめて高機能チャートを表示できます。そのテーマの中で先行して株価の動きがある銘柄、出遅れている銘柄などが一目で分かるので投資戦略を立てるときに重宝します！',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP8 -->
    <img-and-text
      :title="'お気に入り一括表示'"
      :img="'function/favorite.gif'"
      :numbering="'8'"
      :noBorder="false"
      :texts="[
        {
          value:
            '株探プレミアム会員であればお気に入りした銘柄のチャートを一括表示することができます。グループ機能を活用して監視銘柄の動向を１クリックでチェックできるようにしましょう！',
        },
      ]"
    >
    </img-and-text>

    <!-- STEP9 -->
    <img-and-text
      :title="'カスタムリンク'"
      :img="'function/customlink.gif'"
      :numbering="'9'"
      :noBorder="true"
      :texts="[
        {
          value:
            '銘柄ページに他の情報サイトへのリンクを設置できます。リンク先は自由に設定できます。株探を起点にして、足りない情報はカスタムリンクで飛べるように設定すれば、より情報収集が捗ります！',
        },
      ]"
    >
    </img-and-text>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SubHead1 from '../components/SubHead1.vue'
import ImgAndText from '../components/ImgAndText.vue'
export default defineComponent({
  name: 'FunctionView',
  props: {},
  data: function () {
    return {
      noBorder: true,
    }
  },
  components: {
    SubHead1,
    ImgAndText,
  },
})
</script>
