import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import VipView from '@/views/VipView.vue'
import PrivacyPolicyView from '@/views/PrivacyPolicyView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: VipView,
  },
  // {
  //   path: '/vip',
  //   name: 'vip',
  //   component: VipView,
  // },
  // {
  //   path: '/lp',
  //   name: 'lp',
  //   component: VipView,
  // },
  {
    path: '/privacy-policy',
    name: 'privacypolicy',
    component: PrivacyPolicyView,
  },
  {
    path: '/term-of-service',
    name: 'termofservice',
    component: PrivacyPolicyView,
  },
  {
    path: '/specified-comment',
    name: 'specifiedcomment',
    component: PrivacyPolicyView,
  },
  {
    path: '/success',
    name: 'success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessView.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
