import { library } from '@fortawesome/fontawesome-svg-core'
import {
  fas,
  faMapLocationDot,
  faChartLine,
  faXmark,
  faStar,
  faGear,
  faSignOut,
  faChartSimple,
  faHashtag,
  faMoneyBill,
  faHouse,
  faMagnifyingGlassChart,
  faCircleQuestion,
  faArrowRightLong,
  faChevronDown,
  faArrowUpRightFromSquare,
  faFire,
  faPlus,
  faEnvelope,
  faLink,
} from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import FontAwesome from '../components/FontAwesome.vue'

library.add(
  faEnvelope,
  faTwitter,
  faFire,
  faChartLine,
  faMapLocationDot,
  faStar,
  faGear,
  faCopy,
  faXmark,
  faHouse,
  faHashtag,
  faSignOut,
  faMoneyBill,
  faChartSimple,
  faMagnifyingGlassChart,
  faCircleQuestion,
  faArrowRightLong,
  faChevronDown,
  faArrowUpRightFromSquare,
  faPlus,
  faLink
)

export { FontAwesome }
