<template>
  <div :class="`w-full `">
    <div
      v-bind:class="`w-full flex justify-center ${bgColor} ${isHeader ? '' : 'pb-4 md:pb-8'}`"
      v-bind:id="`${bgImage ? 'bg_image' : ''}`"
    >
      <div :class="`max-w-5xl w-full min-w-[375px] flex flex-col items-center justify-center gap-2`">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SectionRow',
  props: {
    bgImage: {
      type: Boolean,
      required: false,
      default: false,
    },
    isHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    bgColor: {
      type: String,
      required: false,
      default: 'white',
    },
  },
})
</script>
