
import { defineComponent } from 'vue'
import { useStore } from '../store'

export default defineComponent({
  setup() {
    const store = useStore()
    return {
      store,
    }
  },
  async mounted() {
    // his.store.dispatch("getProducts");
  },
  methods: {},
  computed: {
    isLoading(): boolean {
      return this.store.state.isLoading
    },
  },
})
